<template>
	<div class="agreement-builder" v-if="agreement" :key="agreement.id" :style="`--v-zoom: ${zoom / 100}; ${styles}`">
		<div class="header-area px-3">
			<div class="header-left" v-if="$vuetify.breakpoint.mdAndUp">
				<span :style="`font-family: '${agreement.styles.h1.fontFamily}'; font-weight:600; font-size: 20px`">{{
					agreement.name
				}}</span>
				<div class="row-format align-center ml-2 pointer" @click="openPrint()">
					<v-icon class="material-symbols-outlined" size="20" :color="agreement.styles.h1.color">print</v-icon>
					<v-icon class="material-symbols-outlined" size="20" :color="agreement.styles.h1.color">picture_as_pdf</v-icon>
				</div>
			</div>
			<div class="header-right" v-if="signer">
				<div class="font-weight-medium" style="color:red; text-decoration: underline" v-if="showFinishWarning">
					<v-icon color="red" size="48">arrow_right_alt</v-icon>
				</div>
				<v-btn v-if="!signer.locked" class="super-action ml-2" @click="finalize"
					><span class="px-6" :style="`font-family: '${agreement.styles.h1.fontFamily}';font-size:16px`">{{
						agreement.settings.finishedButton ? agreement.settings.finishedButton : 'Finish'
					}}</span>
				</v-btn>
				<v-btn v-else class="super-action ml-2" disabled>
					<v-icon>done_all</v-icon>
					<span :style="`font-family: '${agreement.styles.h1.fontFamily}';font-size:16px`"
						>&nbsp; {{ DateTime.fromISO(signer.timestamp).toLocaleString(DateTime.DATETIME_MED) }}</span
					>
				</v-btn>
			</div>
		</div>
		<div style="height: calc(100% - 50px);" class="row-format">
			<div
				class="column-format pb-3 px-2"
				v-if="indexedPages.length && $vuetify.breakpoint.mdAndUp"
				style="border-right: 1px solid var(--v-gray_30-base); height: 100%; max-height: 100%; background-color: var(--v-white-base); min-width: 250px; max-width: 250px"
			>
				<ul class="text-left font-14 py-2">
					<li
						v-for="page in indexedPages"
						:key="page.index"
						class="pointer py-1 font-16 nav-link"
						:style="
							`border-bottom: 1px solid var(--v-gray_30-base); font-family: '${agreement.styles.h1.fontFamily}';`
						"
						@click="scrollToPage(page.index)"
					>
						{{ page.title }}
					</li>
				</ul>
			</div>
			<div style="max-height: calc(100vh - 50px); width:100%; overflow-y: scroll" class="show-scrollbar" id="page-area">
				<div class="zoom" style="min-width: fit-content; width:100%; overflow-x: scroll">
					<div id="print-area">
						<div
							v-for="(page, index) in agreement.pages"
							:key="page.id"
							:id="`page-${index}`"
							class="column-format centered"
						>
							<page
								:id="`page-${index}`"
								:ref="`page-${index}`"
								:page-index="index"
								:agreement="agreement"
								:mce-config="{}"
								:client-mode="clientMode"
								:signer="signer"
								:edit-mode="false"
								:template-mode="false"
								:tokens="tokens"
								:page-width="pageWidth"
								@container-item-signed="itemSigned(page, $event)"
								@signed="itemSigned(page, $event)"
							></page>
							<div class="page-break"></div>
						</div>
					</div>
					<div class="row-format centered my-4">
						<v-btn v-if="!signer.locked" large class="super-action" @click="finalize"
							><span class="px-10" :style="`font-family: '${agreement.styles.h1.fontFamily}';font-size:16px`">{{
								agreement.settings.finishedButton ? agreement.settings.finishedButton : 'Finish'
							}}</span>
						</v-btn>
					</div>
				</div>
			</div>
		</div>
		<div
			style="position: absolute; bottom: 20px; right: 20px"
			class="row-format align-center"
			v-if="agreement.styles.pageSize !== 'RESPONSIVE'"
		>
			<div class="font-12">{{ zoom }}%</div>
			<v-icon class="pointer" @click="zoomIn()" size="24">zoom_in</v-icon>
			<v-icon class="pointer" @click="zoomOut()" size="24">zoom_out</v-icon>
		</div>
	</div>
</template>

<script>
	import { DateTime } from 'luxon';
	import Page from '@/modules/agreements/schema/Page';
	import AgreementMixin from '@/modules/agreements/schema/AgreementMixin';
	import ConfirmModal from '@/components/ConfirmModal';

	export default {
		name: 'AgreementRenderer',

		props: ['agreement', 'signer', 'showFinishWarning'],

		mixins: [AgreementMixin],

		components: { Page },

		data: function() {
			return {
				zoom: 100,
				DateTime: DateTime,
				pageWidth: 0,
				resizeObserver: null,
			};
		},

		mounted() {
			this.initializeZoom();
			addEventListener('resize', () => this.initializeZoom());

			this.resizeObserver = new ResizeObserver(() => {
				this.setPageWidth();
			});

			this.$nextTick(() => this.listenForPageChanges());
		},

		beforeDestroy() {
			this.resizeObserver.unobserve(document.getElementById('page-area'));
		},

		methods: {
			listenForPageChanges: function() {
				this.$nextTick(() => {
					this.resizeObserver.observe(document.getElementById('page-area'));
					this.setPageWidth();
				});
			},

			setPageWidth: function() {
				const page = document.getElementById('page-area');
				this.pageWidth = page.offsetWidth;
			},

			openPrint: function() {
				window.print();
			},

			async finalize() {
				try {
					for (let i = 0; i < this.agreement.pages.length; i++) {
						await this.$refs['page-' + i][0].finalize();
					}
					this.$emit('finalize');
				} catch (err) {
					console.log(err);
					console.log('Error finalizing agreement: ' + err);
				}
			},

			async reset() {
				try {
					for (let i = 0; i < this.agreement.pages.length; i++) {
						await this.$refs['page-' + i][0].reset();
					}
				} catch (err) {
					console.log(err);
					console.log('Error resetting agreement: ' + err);
				}
			},

			handlePostSignature: function() {
				if (this.agreement.settings.signedConfirmationMessage) {
					let binding = {
						bodyText: this.agreement.settings.signedConfirmationMessage,
						hideNo: true,
						yesText: 'OK',
					};
					this.$store.state.globalModalController.openModal(ConfirmModal, binding).then(() => {
						this.handleOptionalRedirect();
					});
				} else {
					this.handleOptionalRedirect();
				}
			},

			handleOptionalRedirect: function() {
				if (this.agreement.settings.redirectUrl) {
					window.location.href = this.agreement.settings.redirectUrl;
				} else {
					setTimeout(() => window.location.reload(), 5000);
				}
			},

			itemSigned: function(page, item) {
				let signer = item.signer;
				let signature = item.signature;
				//signature.containerTarget = item.containerTarget;

				let signerIx = this.agreement.signers.findIndex((s) => s.id === signer.id);
				let signerRecord = this.agreement.signers[signerIx];
				signerRecord.signature = signer.signature;
				signerRecord.signatureType = signer.signatureType;
				signerRecord.initials = signer.initials;
				signerRecord.title = signer.title;
				signerRecord.timestamp = DateTime.now().toISO();
				this.agreement.signers.splice(signerIx, 1, signerRecord);

				if (signature.signedBy.indexOf(signer.id) === -1) {
					signature.signedBy.push(signer.id);
				}
			},

			scrollToPage: function(index) {
				document.getElementById(`page-${index}`).scrollIntoView({ block: 'start', behavior: 'smooth' });
			},

			zoomIn: function() {
				this.zoom = this.zoom + 10;
			},

			zoomOut: function() {
				if (this.zoom > 10) {
					this.zoom = this.zoom - 10;
				}
			},

			handleGestureEvent: function(e) {
				console.log(e);
				console.log(e.scale);
				// node.addEventListener('gestureend', function(e) {
				//   if (e.scale < 1.0) {
				//     // User moved fingers closer together
				//   } else if (e.scale > 1.0) {
				//     // User moved fingers further apart
				//   }
				// }, false);
			},

			initializeZoom: function() {
				if (this.agreement.styles.pageSize === 'RESPONSIVE') {
					return;
				}
				let browserWidth = document.getElementById('page-area').offsetWidth;

				let targetZoom = parseInt((browserWidth / this.widthInPixels) * 100, 10) - 5;
				if (targetZoom <= 100) {
					this.zoom = targetZoom;
					setTimeout(() => {
						let pageArea = document.getElementById('page-area');
						pageArea.scrollLeft = (pageArea.scrollWidth - pageArea.clientWidth) / 2;
					}, 500);
				}
			},
		},

		watch: {
			'$vuetify.breakpoint.mdAndUp': function() {
				this.$nextTick(() => this.initializeZoom());
			},
		},

		computed: {
			tokens: function() {
				return this.getTokensFromAgreement(this.agreement);
			},

			widthInPixels: function() {
				if (this.agreement.styles.pageSize === 'US_LETTER') {
					return this.agreement.styles.landscape ? 1054 : 816;
				} else {
					return this.agreement.styles.landscape ? 1123 : 794;
				}
			},

			indexedPages: function() {
				let result = [];
				this.agreement.pages.forEach((p, index) => {
					result.push({ title: p.title, index: index });
				});
				return result.filter((r) => r.title);
			},

			styles: function() {
				let body = this.agreement.styles.body;
				let h1 = this.agreement.styles.h1;
				let h2 = this.agreement.styles.h2;
				let h3 = this.agreement.styles.h3;
				let baseCSS = `--primary-brand-color: ${this.$store.getters.getCustomBranding.primaryColor}; --canvas-color: ${this.agreement.styles.canvasColor}; --page-color: ${this.agreement.styles.pageColor}; `;
				let bodyCSS = `--body-size: ${body.fontSize}px; --body-weight: ${body.fontWeight}; --body-font: "${body.fontFamily}"; --body-color: ${body.color}; --body-height: ${body.lineHeight}; `;
				let h1CSS = `--h1-size: ${h1.fontSize}px; --h1-weight: ${h1.fontWeight}; --h1-font: "${h1.fontFamily}"; --h1-color: ${h1.color}; --h1-height: ${h1.lineHeight};  `;
				let h2CSS = `--h2-size: ${h2.fontSize}px; --h2-weight: ${h2.fontWeight}; --h2-font: "${h2.fontFamily}"; --h2-color: ${h2.color}; --h2-height: ${h2.lineHeight};  `;
				let h3CSS = `--h3-size: ${h3.fontSize}px; --h3-weight: ${h3.fontWeight}; --h3-font: "${h3.fontFamily}"; --h3-color: ${h3.color}; --h3-height: ${h3.lineHeight};  `;

				return baseCSS + bodyCSS + h1CSS + h2CSS + h3CSS;
			},

			clientMode: function() {
				return this.signer && this.signer.signerType === 'CLIENT';
			},
		},
	};
</script>

<style lang="scss">
	@media print {
		body {
			visibility: hidden;
		}
		#print-area {
			visibility: visible;
			position: absolute;
			left: 0;
			top: 0;

			.page {
				margin: 0 !important;
				width: 100% !important;
				height: 100% !important;
			}

			.page-wrapper {
				padding-top: 12px !important;
			}

			.page-number {
				display: none;
			}

			.shadow {
				filter: none !important;
			}
		}

		.page-break {
			page-break-after: always;
			page-break-inside: avoid;
		}
	}
</style>

<style scoped lang="scss">
	.nav-link {
		&:hover {
			color: var(--v-primary-base);
		}
	}

	@media screen {
		.zoom {
			transform: scale(var(--v-zoom));
			transform-origin: top;
		}
	}

	.agreement-builder {
		background-color: var(--canvas-color);
		height: var(--vh);
		.header-area {
			height: 50px;
			width: 100%;
			background: var(--v-white-base);
			border-bottom: 1px solid var(--v-gray_30-base);
			// Needed for sticky positioning
			position: sticky;
			position: -webkit-sticky;
			top: 0;
			z-index: 203;
			// end sticky

			display: flex;
			justify-content: space-between;
			align-items: center;

			.header-left {
				text-align: left;
				display: flex;
				align-items: center;
				justify-items: flex-start;
				& > div {
					display: flex;
					align-items: center;
					justify-items: flex-start;
				}
				button,
				div {
					&:hover {
						color: var(--v-black-base);
					}
				}
			}
			.header-right {
				display: flex;
				justify-content: flex-end;
				align-items: center;

				div {
					flex: 0 0 auto;
				}
			}
		}
	}
</style>
